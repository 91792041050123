import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { generateUrl } from "functions/router/urlGenerator";
import { useCompanyChoice } from "Components/Contexts/CompanyChoiceContext";
import defaultLogo from "../../../assets/images/france-sport-logo.png";
import { CompanyChoiceForm } from "Components/CompanyChange/CompanyChoiceForm";

export const CompanyChoice = () => {

    const {t} = useTranslation();
    const {pathname} = useLocation();
    const showCompanyChoiceForm = pathname === generateUrl('login'); 

    const {companyChoice} = useCompanyChoice();

    return (
        <div className="auth-logo-wrapper">
            {
                showCompanyChoiceForm ? (
                    <CompanyChoiceForm />
                ): (
                    <div className="auth-logo">
                        <div style={{margin: '30px'}}>
                            <img src={companyChoice.logo ?? defaultLogo} alt={t('logo')} height={50} />
                            <div className="company-choice-item-title">
                                {companyChoice.name}
                            </div>
                        </div>  
                    </div>
                )
            }
        </div>
    )
}
