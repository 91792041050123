import { CustomFetchError } from "../customFetch/customFetch";

export const createAlertTextFromApiError = (
    e: CustomFetchError
): string => {
    const error = e.data;
    let html = '';

    // title
    if(error.title) {
        html += '<strong>' + error.title + ': </strong>';
    } else {
        html += '<strong>Error ' + e.status + ': </strong>';
    }

    // message
    html += ' ' + (error['hydra:description'] ?? (error.message ?? null));
    
    // details
    if(error.data) {
        html += ' Details : ' + JSON.stringify(error.data);
    }

    return html;
}
