//MENU
export const API_MENU = "/api/menu";

//AUTH
export const API_LOGIN =  "/api/login_check";
export const API_REFRESH_TOKEN =  "/api/token/refresh";
export const API_ME = "/api/me";
export const API_RESET_PASSWORD = "/api/auth/reset_password";
export const API_CHANGE_PASSWORD = "/api/auth/change_password";
export const API_FORGOTTEN_PASSWORD = "/api/auth/forgotten_password";

//NOTIFICATIONS
export const API_NOTIFICATIONS = "/api/notifications";

//ENTITY_ATTACHMENTS
export const API_ENTITY_ATTACHMENT = '/api/entity_attachment';
export const API_ENTITY_ATTACHMENTS = '/api/entity_attachments';
export const API_ENTITY_ATTACHMENT_DOWNLOAD = '/api/entity_attachment/download';

//EXPORTS
export const API_DEB_FILE_DOWNLOAD = '/api/deb_file/generate';

//ENTITIES
export const API_USERS = "/api/users";

export const API_COUNTRIES = "/api/countries";
export const API_ADDRESSES = "/api/addresses";

export const API_COMPANIES = "/api/companies";
export const API_COMPANY_LOCATIONS = "/api/company_locations";
export const API_BANK_ACCOUNTS = "/api/bank_accounts";

export const API_ITEMS = '/api/items';
export const API_ITEM_COLLECTIONS = "/api/items_collections";
export const API_ITEM_FAMILIES = "/api/items_families";

export const API_PRICE_LISTS = "/api/price_lists";

export const API_THIRD_PART_CATEGORIES = '/api/third_part_categories';
export const API_CUSTOMERS = '/api/customers';

export const API_CONTACTS = "/api/contacts";
export const API_PAYMENT_TERMS = "/api/payment_terms";
export const API_PAYMENT_METHODS = "/api/payment_methods";
export const API_VAT_RATES = "/api/vat_rates";
export const API_SHIPPING_COST_RANGES = "/api/shipping_cost_ranges";

export const API_PRINTERS = "/api/printers";
export const API_PRINTERS_CHECK = "/api/printer/validate";
export const API_PRINTERS_TEST = "/api/printer/test";

export const API_PROPOSALS = "/api/proposals";
export const API_PROPOSALS_VALIDATE = "/api/proposals/validate";
export const API_PROPOSALS_DEVALIDATE = "/api/proposals/devalidate";
export const API_PROPOSALS_ACCEPT = "/api/proposals/create_orders";
export const API_PROPOSALS_CREATE_INVOICES = "/api/proposals/create_invoices";
export const API_PROPOSAL_PRICING = "/api/proposals/pricing";
export const API_PROPOSAL_ITEMS = "/api/proposal_items";

export const API_ORDERS = "/api/orders";
export const API_ORDERS_VALIDATE = "/api/orders/validate";
export const API_ORDER_PRICING = "/api/orders/pricing";
export const API_ORDER_ITEMS = "/api/order_items";

export const API_DELIVERY_NOTES = "/api/delivery_notes";
export const API_DELIVERY_NOTES_VALIDATE = "/api/delivery_notes/validate";
export const API_DELIVERY_NOTES_MULTIPLE_DOWNLOAD = "/api/download/multiple_deliveryNotes";
export const API_DELIVERY_NOTE_PRICING = "/api/delivery_notes/pricing";
export const API_DELIVERY_NOTE_ITEMS = "/api/delivery_note_items";
export const API_DELIVERY_NOTES_GET_SHIPPED = "/api/composition/delivery_notes/get_shipped_by_dates";

export const API_COMPLAINT_DELIVERY_NOTE_ITEMS = "/api/complaint/get_shipped_delivery_note_from_order";
export const API_COMPLAINT_ORDER_CREATE = "/api/complaint/create_from_order"

export const API_INVOICES = "/api/invoices";
export const API_INVOICES_CREATE_FROM_DELIVERY_NOTES = "/api/invoices/create_from_delivery_notes";
export const API_INVOICE_ITEMS = "/api/invoice_items";
export const API_INVOICE_PRICING = "/api/invoices/pricing";
export const API_INVOICES_VALIDATE = "/api/invoices/validate";
export const API_INVOICE_PDF_REGENERATION = "/api/generate/invoice";

export const API_CREDIT_NOTES = "/api/credit_notes";
export const API_CREDIT_NOTE_GET_INVOICE_ITEMS = "/api/credit_notes/get_invoice_items";

export const API_PAYMENTS = "/api/payments";
export const API_PAYMENTS_GET_UNPAID_INVOICES_AND_CREDIT_NOTES_FOR_CUSTOMER = "/api/invoices/unpayed/customer";
export const API_PAYMENT_DETAILS = "/api/payment_details";

export const API_GET_UNPAYED_LCR = "/api/lcr/get_unpayed_lcr";
export const API_LCR_DELIVERIES = "/api/lcr/payment_deliveries";
export const API_CHECK_DELIVERIES = "/api/check/payment_deliveries";
export const API_PAYMENT_DELIVERIES = "/api/payment_deliveries";
export const API_GET_CUSTOMERS_TO_REMIND = "/api/reminders/invoices";
export const API_SEND_REMINDERS_TO_CUSTOMERS = "/api/reminders/invoices/send";
export const API_PAYMENT_DELIVERIES_MARK_AS_UNPAID = "/api/payment_deliveries/mark_unpaid";
