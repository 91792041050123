import { Modal, ModalBody } from "reactstrap";
import { CompanyChoiceForm } from "./CompanyChoiceForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { generateUrl } from "functions/router/urlGenerator";

type Props = {
    isOpen: boolean,
    close: () => void
}

export const CompanyChangeModal = ({
    isOpen,
    close
}: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const handleSelect = () => {
        navigate(generateUrl('home'));
        close();
    }

    return (
        <Modal size="lg" isOpen={isOpen} toggle={close} centered={true}>
            <ModalBody className="py-3 px-5">
                <div className="mt-2 text-center">
                    <div className="pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>{t('choose_company')}</h4>
                    </div>
                    <div className="company-change-form" style={{margin: '70px 0 60px'}}>
                        <CompanyChoiceForm onSelect={handleSelect} />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}